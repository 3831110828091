import clsx from "clsx";
import React from "react";

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {}

const Card: React.FC<CardProps> = ({ children, className, ...props }) => {
  return (
    <div className={clsx("rounded shadow-md m-2 p-3", className)} {...props}>
      {children}
    </div>
  );
};

export { Card };
