import "@mdi/font/css/materialdesignicons.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { appConfig } from "./config/appConfig";
import keyCloackFactory from "./config/keycloak";
import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";

keyCloackFactory()
  .then((keycloakUser) => {
    if (keycloakUser) {
      return ReactDOM.render(
        <React.StrictMode>
          <App {...keycloakUser} />
        </React.StrictMode>,
        document.getElementById("root")
      );
    }
  })
  .catch(() => {
    window.location.href =
      process.env.NODE_ENV === "production"
        ? appConfig.keycloack.urlLogout.production
        : appConfig.keycloack.urlLogout.development;
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
