import useSWR from "swr";
import { MiniCardInfo } from "../../components/MiniCardInfo";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher } from "../../utils";

const UsersWithScan: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<number>(
    [
      `${appConfig.api.url}/metrics/financial?action=count-users-distinct-upload-tax-receipts`,
      auth.token,
    ],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  return (
    <MiniCardInfo
      mdiIcon="receipt"
      value={data ? data : 0}
      title="Usuários que escanearam NFE"
    />
  );
};

export { UsersWithScan };
