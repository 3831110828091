import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { RoutesConfig } from "../../config/routesConfig";
import { useAuth } from "../../hooks/AuthContext";
import { Dashboard } from "../../pages/Dashboard";
import { NoPermissionToAccessPage } from "../../pages/NoPermissionToAccessPage";

const Routes: React.FC = () => {
  const auth = useAuth();
  const metricsRolePresent = auth.roles.find((role) => role === "metrics");

  return (
    <HashRouter>
      {metricsRolePresent ? (
        <Switch>
          <Route component={Dashboard} path={RoutesConfig.DASHBOARD} />
          <Redirect path="*" to={RoutesConfig.DASHBOARD} />
        </Switch>
      ) : (
        <Switch>
          <Route
            component={NoPermissionToAccessPage}
            path={RoutesConfig.NO_PERMISSION}
          />
          <Redirect path="*" to={RoutesConfig.NO_PERMISSION} />
        </Switch>
      )}
    </HashRouter>
  );
};

export { Routes };
