import errorImg from "../../assets/error.png";
import logoPoshImg from "../../assets/logo.svg";
import { RoutesConfig } from "../../config/routesConfig";

const NoPermissionToAccessPage: React.FC = () => {
  return (
    <div className="h-full">
      <div
        className="h-full bg-cover bg-center flex justify-center items-center flex-col w-100"
        style={{ backgroundImage: `url(${errorImg})` }}
      >
        <div className="bg-red-900 bg-opacity-75 flex justify-center items-center flex-col w-auto rounded-xl px-7 py-10">
          <img className="h-40 w-auto" src={logoPoshImg} alt="PoshCash logo" />
          <h1 className="text-xl text-white">
            Opa, você não tem permissão de entrar aqui :C
          </h1>
          <a
            className="bg-yellow-600 text-white px-6 py-2 mt-8 rounded"
            href={RoutesConfig.LOGOUT}
          >
            Sair
          </a>
        </div>
      </div>
    </div>
  );
};

export { NoPermissionToAccessPage };
