import React from "react";
import { AvailableBalancePosh } from "../../charts/AvailableBalancePosh";
import { BalanceByCompany } from "../../charts/BalanceByCompany";
import { GainPosh } from "../../charts/GainPosh";
import { ReferralCount } from "../../charts/ReferralCount";
import { TotalCashback } from "../../charts/TotalCashback";
import { TotalSales } from "../../charts/TotalSales";
import { TotalSalesPerDay } from "../../charts/TotalSalesPerDay";
import { TotalSalesPerMonth } from "../../charts/TotalSalesPerMonth";
import { TotalSalesPerWeek } from "../../charts/TotalSalesPerWeek";
import { TotalUsers } from "../../charts/TotalUsers";
import { TotalUsersLogin } from "../../charts/TotalUsersLogin";
import { UsersByPlatform } from "../../charts/UsersByPlatform";
import { UsersCreatedPerDay } from "../../charts/UsersCreatedPerDay";
import { UsersCreatedPerMonth } from "../../charts/UsersCreatedPerMonth";
import { UsersCreatedPerWeek } from "../../charts/UsersCreatedPerWeek";
import { UsersWithScan } from "../../charts/UsersWithScan";
import { UsersLastLoginPerDay } from "../../charts/UsersLastLoginPerDay";

const Dashboard: React.FC = () => {
  return (
    <div className="lg:masonry-3">
      <TotalUsers />
      <TotalCashback />
      <TotalUsersLogin />
      <GainPosh />
      <AvailableBalancePosh />
      <UsersWithScan />
      <TotalSales />
      <BalanceByCompany />
      {/* <TotalSalesPerMonth /> */}
      <UsersByPlatform />
      {/* <ScansNFE /> */}
      <UsersLastLoginPerDay />
      <UsersCreatedPerDay />
      <UsersCreatedPerWeek />
      <UsersCreatedPerMonth />
      <TotalSalesPerMonth />
      <TotalSalesPerWeek />
      <TotalSalesPerDay />
      <ReferralCount />
    </div>
  );
};

export { Dashboard };
