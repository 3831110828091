import React from "react";
import useSWR from "swr";
import { MiniCardInfo } from "../../components/MiniCardInfo";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher } from "../../utils";

const TotalCashback: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<number>(
    [
      `${appConfig.api.url}/metrics/financial?action=sum-total-cashback-paid`,
      auth.token,
    ],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  return (
    <MiniCardInfo
      title="Cashback distribuído"
      value={data ? data : 0}
      valueAsCurrency
      mdiIcon="cash-multiple"
    />
  );
};

export { TotalCashback };
