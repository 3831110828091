import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useSWR from "swr";
import { Card } from "../../components/Card";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher, intervalBuilder } from "../../utils";

const ReferralCount: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<{ total: string; class: string }[]>(
    [
      `${appConfig.api.url}/metrics/referralsCount?action=count-by-class`,
      auth.token,
    ],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const [options, setOptions] = useState<ApexOptions>({
    chart: { stacked: true, stackType: "100%" },
    plotOptions: { bar: { borderRadius: 10 } },
    dataLabels: {
      enabled: true,
      formatter: function (val: number, opt) {
        return `${new Intl.NumberFormat("pt-BR", { style: "percent" }).format(
          val / 100
        )} (${opt.w.globals.seriesLog[opt.dataPointIndex]})`;
      },
    },
  });

  useEffect(() => {
    if (!data) return;
    setOptions((oldOptions) => ({
      ...oldOptions,
      xaxis: {
        categories: data.reverse().map((data) => data.class),
        labels: { show: false },
      },
    }));
    setSeries(
      data.reverse().map((data) => {
        return {
          name: intervalBuilder(data.class),
          data: [Number(data.total)],
        };
      })
    );
  }, [data]);

  return (
    <Card className="flex flex-col justify-center break-inside">
      <h4 className="text-xl">Quantidade de indicações</h4>
      <Chart type="bar" options={options} series={series} />
    </Card>
  );
};

export { ReferralCount };
