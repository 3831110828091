import { ApexOptions } from "apexcharts";
import { parse } from "date-fns";
import format from "date-fns/format";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useSWR from "swr";
import { Card } from "../../components/Card";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher } from "../../utils";

const UsersCreatedPerWeek: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<{ date: string; total: number }[]>(
    [
      `${appConfig.api.url}/metrics/usersCount?action=count-base&granulation=week&limit=20`,
      auth.token,
    ],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  const [options, setOptions] = useState<ApexOptions>({
    stroke: { curve: "smooth" },
    markers: { size: 5, strokeColors: "#fff", strokeWidth: 2 },
  });
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);

  useEffect(() => {
    if (!data) return;
    setSeries([
      {
        name: "Usuários por semana",
        data: data.map((createPerDay) => createPerDay.total),
      },
    ]);
    setOptions((oldOptions) => ({
      ...oldOptions,
      xaxis: {
        categories: data.map((createPerDay) =>
          format(parse(createPerDay.date, "dd/MM/yyyy", new Date()), "dd/MM")
        ),
      },
    }));
  }, [data]);

  return (
    <Card className="flex flex-col justify-center break-inside">
      <h4 className="text-xl">Total de usuários novos semana</h4>
      <Chart type="line" options={options} series={series} />
    </Card>
  );
};

export { UsersCreatedPerWeek };
