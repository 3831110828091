import ApexCharts from "apexcharts";
import React, { useEffect } from "react";
import useSWR from "swr";
import { Card } from "../../components/Card";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher } from "../../utils";

const UsersByPlatform: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<{ device_info: string | null; count: string }[]>(
    [`${appConfig.api.url}/metrics/usersCount?action=count-os`, auth.token],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  useEffect(() => {
    if (!data) return;
    let options = {
      chart: { type: "donut" },
      series: data.map((ele) => Number(ele.count)),
      labels: data.map((ele) =>
        ele.device_info ? ele.device_info : "Desconhecido"
      ),
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "Total dispositivos",
              },
            },
          },
        },
      },
    };

    const platforms = data
      .map((ele) => {
        return {
          platform: ele.device_info
            ? ele.device_info.toLowerCase().includes("android")
              ? "Android"
              : "IOS"
            : "Desconhecido",
          count: Number(ele.count),
        };
      })
      .reduce(
        (a, b) => {
          if (b.platform === "Android") {
            a["Android"] += b.count;
          } else {
            if (b.platform === "IOS") {
              a["IOS"] += b.count;
            } else {
              a["Desconhecido"] += b.count;
            }
          }
          return a;
        },
        { Android: 0, IOS: 0, Desconhecido: 0 }
      );

    let options2 = {
      chart: { type: "donut" },
      //@ts-ignore
      series: Object.keys(platforms).map((key) => platforms[key]),
      labels: Object.keys(platforms).map((key) => key),
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "Total dispositivos",
                formatter: () =>
                  platforms.Android + platforms.IOS + platforms.Desconhecido,
              },
            },
          },
        },
      },
    };

    let chart = new ApexCharts(document.querySelector("#chart2"), options);
    let chart2 = new ApexCharts(document.querySelector("#chart2-1"), options2);

    chart.render();
    chart2.render();
  }, [data]);

  return (
    <Card className="flex flex-col justify-center break-inside">
      <h4 className="text-xl">Usuários por plataforma</h4>
      <div id="chart2"></div>
      <div id="chart2-1"></div>
    </Card>
  );
};

export { UsersByPlatform };
