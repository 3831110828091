import useSWR from "swr";
import { MiniCardInfo } from "../../components/MiniCardInfo";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher } from "../../utils";

const TotalUsers: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<number>(
    [`${appConfig.api.url}/metrics/usersCount?action=count-all`, auth.token],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  return (
    <MiniCardInfo
      value={data ? data : 0}
      title="Usuários cadastrados"
      mdiIcon="account"
    />
  );
};

export { TotalUsers };
