import clsx from "clsx";
import CountUp from "react-countup";
import { Card, CardProps } from "../../components/Card";

export interface MiniCardInfoProps extends CardProps {
  mdiIcon: string;
  value: number;
  valueAsCurrency?: boolean;
  title: string;
}

const MiniCardInfo: React.FC<MiniCardInfoProps> = ({
  mdiIcon,
  value,
  title,
  valueAsCurrency = false,
  className,
  ...props
}) => {
  const duration = 2;

  return (
    <Card className={clsx(className, "break-inside")} {...props}>
      <div className="flex flex-wrap items-center justify-center m-3">
        <div className="rounded-full bg-red-800 p-2">
          <i className={`mdi mdi-${mdiIcon} text-4xl text-white`}></i>
        </div>
        <div className="p-3">
          <h4 className="text-2xl text-center font-bold">
            {valueAsCurrency ? (
              <CountUp
                start={0}
                end={value as number}
                duration={duration}
                delay={0}
                separator="."
                decimals={2}
                decimal=","
                prefix="R$ "
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            ) : (
              <CountUp
                start={0}
                end={value as number}
                duration={duration}
                delay={0}
              >
                {({ countUpRef }) => (
                  <div>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            )}
          </h4>
          <h6 className="text-xl text-center">{title}</h6>
        </div>
      </div>
    </Card>
  );
};

export { MiniCardInfo };
