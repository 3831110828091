import useSWR from "swr";
import { MiniCardInfo } from "../../components/MiniCardInfo";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher } from "../../utils";

const AvailableBalancePosh: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<{ available: number }>(
    [
      `${appConfig.api.url}/metrics/financial?action=poshcash-available-balance`,
      auth.token,
    ],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  return (
    <MiniCardInfo
      mdiIcon="currency-usd"
      value={data ? data.available : 0}
      valueAsCurrency
      title="Poshcash saldo em conta"
    />
  );
};

export { AvailableBalancePosh };
