import React, { createContext, useContext } from "react";

export interface AuthProps {
  token: string;
  name: string;
  roles: string[];
}

interface AuthContextData {
  token: string;
  roles: string[];
  name: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<AuthProps> = ({ children, ...props }) => {
  return (
    <AuthContext.Provider value={{ ...props }}>{children}</AuthContext.Provider>
  );
};

const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser utilizado dentro de um AuthProvider");
  }
  return context;
};

export { useAuth, AuthProvider };
