import axios from "axios";

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const fetcher = (url: string, token: string) =>
  axios
    .get(url, { headers: { Authorization: "Bearer " + token } })
    .then((resp) => resp.data);

export function intervalBuilder(expression: string) {
  const regExp = /(\(|\[)(\d+)?,(\d+)?(\)|\])/;

  const match = expression.match(regExp);
  let result = "";
  if (!match) {
    return `${expression} indicação(ões)`;
  }
  if (match[2] === match[3] || !match[2] || !match[3]) {
    return `${match[2]} indicação(ões)`;
  }
  if (match[2]) {
    result += `entre ${match[2]} `;
  }
  if (match[3]) {
    result += `e ${match[3]} indicação(ões)`;
  }
  return result;
}
