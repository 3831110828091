import React from "react";
import { Routes } from "../components/Routes";
import { AuthProps, AuthProvider } from "../hooks/AuthContext";

const App: React.FC<AuthProps> = ({ children, ...props }) => {
  return (
    <AuthProvider {...props}>
      <Routes />
    </AuthProvider>
  );
};

export default App;
