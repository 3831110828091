import Keycloak from "keycloak-js";
import { appConfig } from "./appConfig";

/**
 * Authenticate user using KeyCloak
 */
const keyCloackFactory = async () => {
  try {
    const keycloak = Keycloak({
      url: appConfig.keycloack.urlServer,
      clientId: appConfig.keycloack.clientID,
      realm: appConfig.keycloack.realm,
    });
    const auth = await keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    });
    if (!auth) {
      window.location.reload();
    }
    if (keycloak && keycloak.tokenParsed) {
      const profile = await keycloak.loadUserProfile();
      return {
        token: keycloak.token as string,
        name: profile.firstName as string,
        roles: keycloak.realmAccess ? keycloak.realmAccess.roles : [],
      };
    }
  } catch (error) {
    throw new Error(error);
  }
};

export default keyCloackFactory;
