import useSWR from "swr";
import { MiniCardInfo } from "../../components/MiniCardInfo";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher } from "../../utils";

const TotalSales: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<number>(
    [
      `${appConfig.api.url}/metrics/financial?action=sum-total-all-tax-receipts`,
      auth.token,
    ],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  return (
    <MiniCardInfo
      mdiIcon="finance"
      value={data ? data : 0}
      valueAsCurrency
      title="Total de vendas"
    />
  );
};

export { TotalSales };
