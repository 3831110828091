import { ApexOptions } from "apexcharts";
import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import useSWR from "swr";
import { Card } from "../../components/Card";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher, formatCurrency } from "../../utils";

const TotalSalesPerDay: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<{ date: string; total: number }[]>(
    [
      `${appConfig.api.url}/metrics/financial?action=sum-total-all-tax-receipts-by-date&granulation=day`,
      auth.token,
    ],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  const dataOrder = useMemo(
    () => (data ? data.reverse().slice(0, 20).reverse() : []),
    [data]
  );

  const [options, setOptions] = useState<ApexOptions>({
    stroke: { curve: "smooth" },
    markers: { size: 5, strokeColors: "#fff", strokeWidth: 2 },
    yaxis: {
      labels: { formatter: (value: number) => formatCurrency(value) },
    },
  });
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);

  useEffect(() => {
    setSeries([
      {
        name: "Total de vendas",
        data: dataOrder.map((salesPerMonth) => salesPerMonth.total),
      },
    ]);
    setOptions((oldOptions) => ({
      ...oldOptions,
      xaxis: {
        categories: dataOrder.map((createPerDay) => createPerDay.date),
      },
    }));
  }, [dataOrder]);

  return (
    <Card className="flex flex-col justify-center break-inside">
      <h4 className="text-xl">Total de vendas por dia</h4>
      <Chart type="line" options={options} series={series} />
    </Card>
  );
};

export { TotalSalesPerDay };
