export const appConfig = {
  keycloack: {
    urlServer: "https://auth.poshcash.com.br/auth",
    clientID: "posh-client",
    realm: "master",
    urlLogout: {
      development:
        "https://auth.poshcash.com.br/auth/realms/master/protocol/openid-connect/logout?redirect_uri=http://localhost:3000",
      production:
        "https://auth.poshcash.com.br/auth/realms/master/protocol/openid-connect/logout?redirect_uri=https://metrics.poshcash.com.br/auth",
    },
  },
  api: {
    url: "https://wrapper.poshcash.com.br/rest",
    refreshInterval: 1000 * 60,
  },
};
