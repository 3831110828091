import process from "process";
import { appConfig } from "./appConfig";

export const RoutesConfig = {
  DASHBOARD: "/dashboard",
  NO_PERMISSION: "/no-permission",
  LOGOUT:
    process.env.NODE_ENV === "production"
      ? appConfig.keycloack.urlLogout.production
      : appConfig.keycloack.urlLogout.development,
};
