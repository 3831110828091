import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useSWR from "swr";
import { Card } from "../../components/Card";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../hooks/AuthContext";
import { fetcher, formatCurrency } from "../../utils";

const BalanceByCompany: React.FC = () => {
  const auth = useAuth();
  const { data } = useSWR<{ quantity: number; company: { name: string } }[]>(
    [`${appConfig.api.url}/metrics/companies/balances`, auth.token],
    (url, token) => fetcher(url, token),
    { refreshInterval: appConfig.api.refreshInterval }
  );

  const [series, setSeries] = useState<{ name: string; data: any[] }[]>([]);

  const [options, setOptions] = useState<ApexOptions>({
    chart: { height: 350, type: "bar" },
    plotOptions: { bar: { borderRadius: 10, distributed: true } },
    dataLabels: { enabled: false },
    yaxis: { labels: { formatter: (val: number) => formatCurrency(val) } },
  });

  useEffect(() => {
    if (!data) return;
    setOptions((oldOptions) => ({
      ...oldOptions,
      xaxis: {
        categories: data.map(({ company }) => company.name),
        labels: { show: false },
      },
    }));
    setSeries([{ name: "Saldo", data: data.map(({ quantity }) => quantity) }]);
  }, [data]);

  return (
    <Card>
      <h4 className="text-xl">Saldo de empresas</h4>
      <Chart options={options} series={series} type="bar" />
    </Card>
  );
};

export { BalanceByCompany };
